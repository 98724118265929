const initOurClients = async () => {
  const sections = Array.from(document.getElementsByClassName('b-our-clients'));

  for (const section of sections) {
    const sliderEl = section.querySelector('.swiper');

    if (!sliderEl) {
      continue;
    }

    const {Swiper} = await import('swiper');

    new Swiper(sliderEl, {
      slidesPerView: 'auto',
      spaceBetween: 40,
      breakpoints: {
        1200: {
          spaceBetween: 80,
        },
      },
    });
  }
};

export default initOurClients;
