const bottomLinksAnim = () => {
  const linksContainer = document.querySelector('[data-bottom-links-anim] .scroll-container');
  if (!linksContainer) {
    return;
  }
  const originalLinks = Array.from(linksContainer.querySelectorAll('a'));
  const totalLinks = originalLinks.length;
  const linkHeight = originalLinks[0].offsetHeight;
  let currentIndex = 0;
  linksContainer.style.height = `${linkHeight}px`;

  function moveLinks() {
    currentIndex = (currentIndex + 1) % totalLinks;
    linksContainer.style.transform = `translateY(-${currentIndex * linkHeight}px)`;

    if (currentIndex === 0) {
      setTimeout(() => {
        linksContainer.style.transition = 'none';
        linksContainer.style.transform = 'translateY(0)';
        setTimeout(() => {
          linksContainer.style.transition = 'transform 0.5s ease-in-out';
        }, 20);
      }, 500);
    }
  }
  setInterval(moveLinks, 6000);
};

export default bottomLinksAnim;
